<template>
  <div class="d-flex">
    <div
      class="div mx-2"
      v-for="perm of permissions"
      :key="perm.type"
      @click.stop="deletePermission(perm.id)"
    >
      <template v-if="perm.elements.length !== 0">
        <span
          v-if="perm.elements[0] && perm.elements[0].length >= 10"
          v-b-tooltip="perm.elements.toString()"
        >
          <v-icon small>
            {{ icons[perm.type] }}
          </v-icon>
          <v-icon x-small class="ml-1"> far fa-calendar-alt </v-icon>
        </span>
        <v-icon small v-else> {{ icons[perm.type] }} </v-icon>
        <v-icon v-if="perm.elements[0] === 'infinite'" x-small>fas fa-infinity</v-icon>
        <small v-if="!isNaN(parseInt(perm.elements[0]))">{{ perm.elements[0] + "dni" }}</small>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeViewPermissions",
  data() {
    return {
      loader: true,
      icons: {
        view: "far fa-eye",
        create: "fas fa-cogs",
        edit: "fas fa-pencil-alt",
        delete: "fas fa-trash",
      },
      permissions: {
        view: {
          type: "view",
          id: [],
          elements: [],
        },
        edit: {
          type: "edit",
          id: [],
          elements: [],
        },
        create: {
          type: "create",
          id: [],
          elements: [],
        },
        delete: {
          type: "delete",
          id: [],
          elements: [],
        },
      },
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    deletePermission(id) {
      this.$emit("deletePermission", id);
    },
    sortPermissions() {
      for (let permission of this.item.permissions) {
        let { elements } = this.permissions[permission.action];
        const { timeRule } = permission;
        this.permissions[permission.action].id.push(permission.id);
        switch (permission.timeRule.type) {
          case "range":
            elements.push(
              `od${timeRule.begins_on.slice(0, 11)} do ${permission.timeRule.expires_on.slice(
                0,
                11
              )}`
            );
            break;
          case "instant":
            elements.push(timeRule.expires_after);
            break;
          case "infinite":
            elements.push(timeRule.type);
            break;
          default:
            break;
        }
      }
    },
  },
  created() {
    this.sortPermissions();
  },
};
</script>
